import * as React from 'react';
import {SVGProps} from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={108}
    height={27}
    viewBox={'0 0 108 27'}
    {...props}
    fill="none"
    stroke="none">
    <rect
      width={107}
      height={26}
      x={0.5}
      y={0.5}
      fill="#033337"
      fillOpacity={0.8}
      stroke="url(#seeAll)"
      rx={13}
    />
    <defs>
      <linearGradient
        id="seeAll"
        x1={0}
        x2={108}
        y1={13.5}
        y2={13.5}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={0.32} stopColor="#003B49" />
        <stop offset={0.68} stopColor="#003B49" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgComponent;
