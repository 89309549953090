import * as React from 'react';
import {SVGProps} from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={92}
    height={89}
    viewBox={'0 0 92 89'}
    {...props}
    fill={'none'}
    stroke={'none'}>
    <rect
      width={91}
      height={88}
      x={0.5}
      y={0.5}
      fill={props.fill || '#033337'}
      fillOpacity={0.6}
      stroke="url(#circle)"
      rx={44}
    />
    <defs>
      <linearGradient
        id="circle"
        x1={0}
        x2={92}
        y1={44.5}
        y2={44.5}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={0.32} stopColor="#003B49" />
        <stop offset={0.68} stopColor="#003B49" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgComponent;
