import React from 'react';
import styled, {css} from 'styled-components';
import myPressable from '../myPressable';
import {NewsCardProps} from '../../interfaces/components/newsCard';
import Image from '../Image';
import defaultImage from '../../assets/images/loginBackgroundMobile.jpg';
import {useIsPresent} from 'framer-motion';
import {isSmallHeight} from '../../utils/responsive';
import {removeHtmlTags} from '../../utils/helper';

const Container = styled(myPressable)`
  width: 100%;
  border-radius: 10px;
  position: relative;
  background-image: url(${require('../../assets/images/newsCardBackground.png')});
  background-size: cover;
  background-position: center;
  border: 2px solid ${({theme}) => theme.text.light};
  padding: 0;
  box-shadow: 0 4px 10px ${({theme}) => theme.shadow};
  min-height: ${isSmallHeight ? 6 : 7}rem;
  transform-origin: center;
`;

const GradientContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 9px;
  background: linear-gradient(
    to bottom,
    ${({theme}) => theme.primary + 'CC'},
    ${({theme}) => theme.secondary + 'CC'}
  );
  backdrop-filter: blur(2px);
  will-change: backdrop-filter;
  overflow: hidden;
  z-index: 1;
`;
const ContentContainer = styled.div`
  width: 94%;
  flex-direction: column;
  padding: 3%;
  flex: 3;
  justify-content: flex-start;
  @media (max-width: 768px) {
    width: 92%;
    padding: 2% 3%;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  width: 25%;
  aspect-ratio: 1;
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  z-index: 2;
`;

const Title = styled.p<{isSmall: boolean}>`
  font-size: ${({theme}) => theme.text.s7};
  line-height: 1.2rem;
  color: ${({theme}) => theme.text.light};
  text-align: left;
  font-weight: bold;
  ${({isSmall}) =>
    !isSmall &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
`;

const Description = styled.div`
  font-size: ${({theme}) => theme.text.s9};
  line-height: ${({theme}) => theme.text.s7};
  color: ${({theme}) => theme.text.light};
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 768px) {
    -webkit-line-clamp: 3;
  }
`;

const TitleContainer = styled.div<any>`
  display: flex;
  ${({isSmall}) =>
    isSmall &&
    `
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  `}
  margin-bottom: 0.7rem;
`;

const DescriptionContainer = styled.div`
  display: flex;
`;

const NewsCard = ({
  title,
  titleStyle,
  description,
  descriptionStyle,
  style,
  onPress,
  isSmall,
  image = null,
}: NewsCardProps): JSX.Element => {
  const isPresent = useIsPresent();
  const animations = {
    style: {
      position: isPresent ? 'static' : 'absolute',
    },
    initial: {scale: 0, opacity: 0},
    animate: {scale: 1, opacity: 1},
    exit: {scale: 0, opacity: 0},
    transition: {type: 'spring', stiffness: 900, damping: 40},
  };

  return (
    <Container
      containerStyle={{
        overflow: 'visible',
        borderWidth: 1,
        ...(style || {}),
      }}
      onClick={onPress}>
      <GradientContainer />
      <Row>
        {image !== null && (
          <ImageContainer>
            <Image source={image ?? defaultImage} />
          </ImageContainer>
        )}

        <ContentContainer>
          {!!title && (
            <TitleContainer isSmall={isSmall}>
              <Title isSmall={isSmall} style={titleStyle}>
                {title}
              </Title>
            </TitleContainer>
          )}
          {!!description && !isSmall && (
            <DescriptionContainer>
              <Description style={descriptionStyle}>
                {removeHtmlTags(description)}
              </Description>
            </DescriptionContainer>
          )}
        </ContentContainer>
      </Row>
    </Container>
  );
};

export default NewsCard;
