import {auth} from './auth';
import {news} from './content/news';
import {qna} from './content/qna';
import {home} from './content/home';
import {summaries} from './content/summaries';
import {predictions} from './content/predictions';
import {analysis} from './content/analysis';

export const services = {
  auth,
  news,
  home,
  qna,
  summaries,
  predictions,
  analysis,
};
