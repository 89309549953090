import http from '../http';
import {api} from '../api';
import {
  GetSummariesByDateBody,
  GetSummariesByDateResponse,
  GetSummariesByTabResponse,
  GetSummaryDetailsResponse,
  GetSummaryVoiceUrlResponse,
} from '../../interfaces/api/summaries';
import {store} from '../../redux/store';

export const summaries = {
  getByTab: (tabId: number, page: number): Promise<GetSummariesByTabResponse> =>
    new Promise((resolve, reject) => {
      const url = `${api.summaries.getByTab}?tab_id=${tabId}&page=${page}`;
      http
        .get(url)
        .then(res => resolve(res as GetSummariesByTabResponse))
        .catch(e => reject(e));
    }),

  getById: (id: number): Promise<GetSummaryDetailsResponse> =>
    new Promise((resolve, reject) => {
      const url = `${api.summaries.getById}${id}`;
      http
        .get(url)
        .then(res => resolve(res as GetSummaryDetailsResponse))
        .catch(e => reject(e));
    }),

  getByDate: ({
    tabId,
    dateFrom,
    dateTo,
    page,
    filters,
  }: GetSummariesByDateBody): Promise<GetSummariesByDateResponse> =>
    new Promise((resolve, reject) => {
      const {speciality_id} = store.getState().auth?.currentUser || {};
      const params = new URLSearchParams();
      if (!tabId || !speciality_id) {
        throw new Error('tab id and speciality id are required');
      }
      params.append('tab_id', tabId.toString());
      params.append('speciality_id', speciality_id.toString());
      Object.keys(filters || {}).forEach(key => {
        if (
          filters[key] &&
          (key === 'subspeciality_id' ||
            key === 'type_of_study_id' ||
            key === 'resource_id')
        ) {
          params.append(key, String(filters[key]));
        }
      });
      params.append('dateFrom', dateFrom.toString());
      if (dateTo) {
        params.append('dateTo', dateTo.toString());
      }

      if (page) {
        params.append('page', page.toString());
        params.append('pageSize', '10');
      }
      const url = `${api.summaries.getByDateRange}?${params.toString()}`;
      http
        .get(url)
        .then(res => resolve(res as GetSummariesByDateResponse))
        .catch(e => reject(e));
    }),

  getVoiceUrl: (id: number): Promise<GetSummaryVoiceUrlResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.summaries.generateVoice, {id})
        .then(res => resolve(res as GetSummaryVoiceUrlResponse))
        .catch(e => reject(e));
    }),
};
