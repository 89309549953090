import http from '../http';
import {api} from '../api';
import {
  GetPredictionsByDateBody,
  GetPredictionsByDateResponse,
  GetPredictionsByTabResponse,
  GetPredictionDetailsResponse,
} from '../../interfaces/api/predictions';
import {store} from '../../redux/store';
import {showGlobalToast} from '../../connected-components/toast/actions';

export const predictions = {
  getByTab: (
    tabId: number,
    page: number,
    pageSize: number = 20,
  ): Promise<GetPredictionsByTabResponse> =>
    new Promise((resolve, reject) => {
      const url = `${api.predictions.getByTab}?tab_id=${tabId}&page=${page}&pageSize=${pageSize || 20}`;
      http
        .get(url)
        .then(res => resolve(res as GetPredictionsByTabResponse))
        .catch(e => reject(e));
    }),

  getById: (id: number): Promise<GetPredictionDetailsResponse> =>
    new Promise((resolve, reject) => {
      const url = `${api.predictions.getById}${id}`;
      http
        .get(url)
        .then(res => resolve(res as GetPredictionDetailsResponse))
        .catch(e => reject(e));
    }),

  getByDate: ({
    tabId,
    dateFrom,
    dateTo,
    page,
    pageSize,
    filters,
  }: GetPredictionsByDateBody): Promise<GetPredictionsByDateResponse> =>
    new Promise((resolve, reject) => {
      const {speciality_id} = store.getState().auth?.currentUser || {};
      const params = new URLSearchParams();
      if (!tabId || !speciality_id) {
        throw new Error('tab id and speciality id are required');
      }
      params.append('tab_id', tabId.toString());
      params.append('speciality_id', speciality_id.toString());
      Object.keys(filters || {}).forEach(key => {
        if (
          filters[key] &&
          (key === 'subspeciality_id' ||
            key === 'type_of_study_id' ||
            key === 'resource_id')
        ) {
          params.append(key, String(filters[key]));
        }
      });
      params.append('dateFrom', dateFrom.toString());
      if (dateTo) {
        params.append('dateTo', dateTo.toString());
      }

      if (page) {
        params.append('page', page.toString());
        params.append('pageSize', (pageSize || 20).toString());
      }
      const url = `${api.predictions.getByDateRange}?${params.toString()}`;
      http
        .get(url)
        .then(res => resolve(res as GetPredictionsByDateResponse))
        .catch(e => reject(e));
    }),
};
