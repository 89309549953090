import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FiltersType} from '../../../interfaces/api/tabs';
import {FiltersState} from '../../../interfaces/redux/filters';

const initialState: FiltersState = {
  data: {},
  isLoading: true,
  isError: false,
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    filtersLoading: state => {
      state.isLoading = true;
      state.isError = false;
      state.data = {};
    },
    filtersFail: state => {
      state.isLoading = false;
      state.isError = true;
    },
    setFilters: (
      state,
      action: PayloadAction<{[key: string]: FiltersType}>,
    ) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {filtersLoading, filtersFail, setFilters} = filtersSlice.actions;

export default filtersSlice.reducer;
