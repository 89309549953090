import {services} from '../../../api';
import {HomeTabsData} from '../../../interfaces/api/content/home';
import {RecommendationsData} from '../../../interfaces/api/content/recomendations';
import {
  NewsItemDetailsType,
  SearchNewsForm,
} from '../../../interfaces/api/tabs';
import {store} from '../../../redux/store';
import {filtersFail, filtersLoading, setFilters} from './reducer';

/**
 * this function to get home tabs with recommendations
 *
 * @return {HomeTabsData | null} result of home tabs and recommendations
 */
export const getHomeTabs = async (): Promise<HomeTabsData | null> => {
  try {
    const res = await services.home.getHomeTabs();
    return res?.data;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Home Tabs FAIL: ', error);
    }
    return null;
  }
};

/**
 * this function to get recommendation list
 *
 * @return {RecommendationsData | null} recommendation list
 */
export const getRecommendations = async (
  page: number,
  pageSize?: number,
): Promise<RecommendationsData | null> => {
  try {
    const res = await services.home.getRecommendations(page, pageSize);
    return res?.data;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Recommendations List FAIL: ', error);
    }
    return null;
  }
};

/**
 * this function to get recommendation list by tab id
 *
 * @return {RecommendationsData | null} recommendation list
 */
export const getRecommendationsByTab = async (
  body: SearchNewsForm,
): Promise<RecommendationsData | null> => {
  try {
    const res = await services.home.getRecommendationsByTab(body);
    return res?.data;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Recommendations List By Tab Id FAIL: ', error);
    }
    return null;
  }
};

/**
 * this function to get recommendation Details
 *
 * @return {NewsItemDetailsType | null} recommendation Details
 */
export const getRecommendationDetails = async (
  id: number,
): Promise<NewsItemDetailsType | null> => {
  try {
    const res = await services.home.getRecommendationDetails(id);
    if (Array.isArray(res?.data)) {
      return res?.data.length > 0 ? res?.data[0] : null;
    }
    return res?.data;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Recommendation Details FAIL: ', error);
    }
    return null;
  }
};

/**
 * this function to get Tabs Filters
 */
export const getTabsFilters = async () => {
  try {
    store.dispatch(filtersLoading());
    const {speciality_id} = store.getState()?.auth?.currentUser || {};
    if (speciality_id === undefined) {
      throw new Error('speciality_id required');
    }
    const res = await services.home.getTabsFilters(speciality_id);
    if (Object.keys(res?.data?.data || {}).length > 0) {
      store.dispatch(setFilters(res?.data?.data));
    }
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Tabs Filters FAIL: ', error);
    }
    store.dispatch(filtersFail());
  }
};
